import React, { useState } from "react";

const Menus = () => {
  const [dropdown, setDropdown] = useState(false);

  return (
    <div id="menus" className="w-screen lg:w-full xl:w-11/12 my-20 mx-auto">
      <h2 className="font-primary lowercase text-4xl text-th-brown font-extrabold tracking-widest text-center">
        MENUS
      </h2>
      <div className="h-fit flex flex-col lg:flex-row justify-center items-center gap-10 lg:gap-3 2xl:gap-10 mt-10">
        <div className="w-11/12 xl:w-1/2 h-full md:h-[350px] lg:h-[400px] 2xl:h-[310px] bg-th-red flex flex-col md:flex-row">
          <img
            src="assets/images/menu.jpg"
            className="object-cover w-full md:w-1/2 h-full max-h-[250px] md:max-h-[800px]"
            alt="dinner_menu"
          />
          <div className="px-5 py-14 w-full md:w-1/2 flex flex-col justify-around lg:justify-center items-center gap-5 md:gap-1">
            <h2 className="font-primary lowercase text-2xl lg:text-3xl text-th-brown font-extrabold tracking-widest mb-2 lg:mb-8">
              food
            </h2>
            <p className="text-base font-secondary text-white text-left mb-1 lg:mb-3">
              Simple authentic Italian antipasti, mains, and desserts crafted
              with seasonal, local ingredients.
            </p>
            <div>
              <button
                onClick={() => setDropdown((prev) => !prev)}
                className="mb-0 lg:mb-1 font-primary lowercase text-lg cursor-pointer bg-th-brown text-th-white px-6 py-1  rounded-md transition-all hover:bg-white hover:text-th-brown flex justify-center items-center"
                type="button"
              >
                food menus
                <svg
                  className="w-5 h-5 ml-2 mt-1.5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </button>
              {/* <div className={`${dropdown ? 'block' : 'hidden'} relative z-20 bg-white divide-y-0 lg:divide-y divide-gray-100 rounded-none lg:rounded-lg shadow-none lg:shadow w-auto lg:w-44`}> */}
              <div
                className={`${
                  dropdown ? "block" : "hidden"
                } absolute z-20 bg-white divide-y-0 lg:divide-y divide-gray-100 rounded-lg shadow-none lg:shadow w-auto lg:w-44`}
              >
                <ul className="py-2 text-sm text-black font-primary">
                  <li>
                    <a
                      href="assets/menus/the_farmhouse_Brunch.pdf"
                      target="_blank"
                      className="block px-4 py-2 hover:bg-gray-100"
                    >
                      brunch
                    </a>
                  </li>
                  <li>
                    <a
                      href="assets/menus/the_farmhouse_Lunch_Menu.pdf"
                      target="_blank"
                      className="block px-4 py-2 hover:bg-gray-100"
                    >
                      lunch
                    </a>
                  </li>
                  <li>
                    <a
                      href="assets/menus/the_farmhouse_Main_Menu.pdf"
                      target="_blank"
                      className="block px-4 py-2 hover:bg-gray-100"
                    >
                      dinner
                    </a>
                  </li>
                  <li>
                    <a
                      href="assets/menus/the_farmhouse_Mangia_Wednesdays.pdf"
                      target="_blank"
                      className="block px-4 py-2 hover:bg-gray-100"
                    >
                      wednesday's feature
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="assets/menus/the_farmhouse_holiday_features.pdf"
                      target="_blank"
                      className="block px-4 py-2 hover:bg-gray-100"
                    >
                      holiday features
                    </a>
                  </li> */}
                  {/* <li>
                    <a
                      href="assets/menus/the_farmhouse_new_year.pdf"
                      target="_blank"
                      className="block px-4 py-2 hover:bg-gray-100"
                    >
                      new year's eve
                    </a>
                  </li> */}
                  <li>
                    <a
                      href="assets/menus/the_farmhouse_prix_fixe.pdf"
                      target="_blank"
                      className="block px-4 py-2 hover:bg-gray-100"
                    >
                      classic weekday menu
                    </a>
                  </li>
                  <li>
                    <a
                      href="assets/menus/the_farmhouse _Dessert.pdf"
                      target="_blank"
                      className="block px-4 py-2 hover:bg-gray-100"
                    >
                      dessert
                    </a>
                  </li>
                  <li>
                    <a
                      href="assets/menus/the_farmhouse_Happy_Hour.pdf"
                      target="_blank"
                      className="block px-4 py-2 hover:bg-gray-100"
                    >
                      happy hour
                    </a>
                  </li>
                  <li>
                    <a
                      href="/dine-out"
                      className="block px-4 py-2 hover:bg-gray-100"
                    >
                      dine out
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {/* <div className='flex flex-col lg:flex-row gap-2 lg:gap-3 justify-center items-center flex-wrap text-center'>
    <a href='assets/menus/the_farmhouse_Brunch.pdf' target='_blank'rel="noreferrer" className="mb-0 lg:mb-1 font-primary lowercase text-lg cursor-pointer bg-th-brown text-th-white px-6 py-1 rounded-md transition-all hover:bg-white hover:text-th-brown">brunch</a>
    <a href='assets/menus/the_farmhouse_Lunch_Menu.pdf' target='_blank'rel="noreferrer" className="mb-0 lg:mb-1 font-primary lowercase text-lg cursor-pointer bg-th-brown text-th-white px-6 py-1 rounded-md transition-all hover:bg-white hover:text-th-brown">lunch</a>
    <a href='assets/menus/the_farmhouse_Main_Menu.pdf' target='_blank'rel="noreferrer" className="mb-0 lg:mb-1 font-primary lowercase text-lg cursor-pointer bg-th-brown text-th-white px-6 py-1 rounded-md transition-all hover:bg-white hover:text-th-brown">dinner</a>
    <a href='assets/menus/the_farmhouse_Mangia_Wednesdays.pdf' target='_blank'rel="noreferrer" className="mb-0 lg:mb-1 font-primary lowercase text-lg cursor-pointer bg-th-brown text-th-white px-6 py-1 rounded-md transition-all hover:bg-white hover:text-th-brown">special</a>
    <a href='assets/menus/the_farmhouse _Dessert.pdf' target='_blank'rel="noreferrer" className="mb-0 lg:mb-1 font-primary lowercase text-lg cursor-pointer bg-th-brown text-th-white px-6 py-1 rounded-md transition-all hover:bg-white hover:text-th-brown">dessert</a>
    <a href="assets/menus/the_farmhouse_Happy_Hour.pdf" target='_blank'rel="noreferrer" className="mb-0 lg:mb-1 font-primary lowercase text-lg cursor-pointer bg-th-brown text-th-white px-6 py-1 rounded-md transition-all hover:bg-white hover:text-th-brown">happy hour</a>
    </div> */}
          </div>
        </div>
        <div className="w-11/12 xl:w-1/2 h-full md:h-[350px] lg:h-[400px] 2xl:h-[310px] bg-th-red flex flex-col md:flex-row">
          <img
            src="assets/images/wine.jpg"
            className="object-cover w-full md:w-1/2 h-full max-h-[250px] md:max-h-[800px]"
            alt="dinner_menu"
          />
          <div className="p-5 w-full md:w-1/2 flex flex-col justify-center items-center gap-5 md:gap-0">
            <h2 className=" font-primary lowercase text-2xl lg:text-3xl text-th-brown font-extrabold tracking-widest mb-2 lg:mb-8">
              beverages
            </h2>
            <p className="text-base font-secondary text-white text-left mb-1 lg:mb-3">
              The curated wine list features natural, organic, and biodiverse
              wine from BC and Italy, carefully selected to pair with our
              dishes. Here, there's something for everyone with classic Italian
              cocktails, beers, and non-alcoholic drinks.
            </p>
            <div className="flex flex-col lg:flex-row gap-2 lg:gap-5 justify-center items-center text-center">
              <a
                href="assets/menus/the_Farmhouse_Wine_List.pdf"
                target="_blank"
                rel="noreferrer"
                className="mb-0 lg:mb-1 font-primary lowercase text-lg cursor-pointer bg-th-brown text-th-white px-6 py-1 rounded-md transition-all hover:bg-white hover:text-th-brown"
              >
                wine list
              </a>
              <a
                href="assets/menus/the_farmhouse_Beverages.pdf"
                target="_blank"
                rel="noreferrer"
                className="mb-0 lg:mb-1 font-primary lowercase text-lg cursor-pointer bg-th-brown text-th-white px-6 py-1 rounded-md transition-all hover:bg-white hover:text-th-brown"
              >
                beverages
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menus;
