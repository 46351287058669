import React, { useEffect } from "react";
import Footer from "../components/common/Footer";
import FooterSub from "../components/common/FooterSub";
import Nav from "../components/common/Nav";
import DineOutImg from "../img/dine_out.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import carousel1Web from "../img/dine_out_carousel_1.webp";
import carousel1 from "../img/dine_out_carousel_1.jpg";
import carousel2Web from "../img/dine_out_carousel_2.webp";
import carousel2 from "../img/dine_out_carousel_2.jpg";
import carousel3Web from "../img/dine_out_carousel_3.webp";
import carousel3 from "../img/dine_out_carousel_3.jpg";
import carousel4Web from "../img/dine_out_carousel_4.webp";
import carousel4 from "../img/dine_out_carousel_4.jpg";
import carousel5Web from "../img/dine_out_carousel_5.webp";
import carousel5 from "../img/dine_out_carousel_5.jpg";

const DineOut = () => {
  const images = [
    [carousel1, carousel1Web, "Dine out menu"],
    [carousel2, carousel2Web, "Beet and Smoked gorgonzola salad"],
    [carousel3, carousel3Web, "Polpette"],
    [carousel4, carousel4Web, "Dine out menu"],
    [carousel5, carousel5Web, "Dine out menu with desserts"],
  ];
  const responsive = {
    desktop: {
      breakpoint: { max: 1980, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  useEffect(() => (document.title = "The Farmhouse Vancouver | Dine Out "), []);

  return (
    <div className="min-h-screen overflow-x-hidden">
      <Nav />
      <section className="relative bg-black w-screen h-[200px] lg:h-[280px] 2xl:h-[400px] rounded-bl-[50px] md:rounded-bl-[170px] lg:rounded-bl-[220px] overflow-hidden">
        <div className="absolute top-0 left-0 bg-room bg-no-repeat bg-cover w-full h-full bg-opacity-25 opacity-60 bg-center"></div>
        <h1 className="text-white text-2xl lg:text-5xl font-primary text-center absolute top-1/3 left-1/2 pt-0 lg:pt-10 -translate-x-1/2">
          the farmhouse
        </h1>
        <h1 className="text-white text-2xl lg:text-5xl font-primary text-center absolute top-[45%] left-1/2 pt-0 lg:pt-10 -translate-x-1/2">
          dine out
        </h1>
        <div className="text-white font-primary text-center absolute top-[60%] left-1/2 pt-0 lg:pt-10 -translate-x-1/2"></div>
      </section>
      <section className="bg-group bg-no-repeat bg-right-bottom bg-[length:400px] md:bg-[length:700px] lg:bg-[length:1000px]">
        <div className="flex flex-col items-center">
          <img
            className="xl:max-w-[600px] py-10 scale-110 xl:scale-100"
            src={DineOutImg}
          />
          <a
            href="https://www.tbdine.com/book/restaurant/the-farmhouse-restaurant?idApp=70973&language=en-us"
            className="border border-th-red py-2 px-10 rounded-md bg-th-red text-th-white mb-[119px]"
          >
            BOOK NOW
          </a>
        </div>
        <div className="amenities-section-slideshow py-[25px] md:mx-[50px] lg:mx-[100px]">
          <Carousel
            centerMode
            swipeable={true}
            draggable={false}
            showDots={false}
            responsive={responsive}
            autoPlaySpeed={1000}
            infinite={true}
            keyBoardControl={true}
            transitionDuration={1000}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["mobile"]}
            itemClass="carousel-item"
          >
            {images.map((image) => {
              return (
                <>
                  <span className="absolute bottom-5 left-7 font-secondary text-white text-lg capitalize">
                    {image[2]}
                  </span>
                  <picture>
                    <source type="image/webp" srcset={image[1]} />
                    <img
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                      src={image[0]}
                      alt=""
                    />
                  </picture>
                </>
              );
            })}
          </Carousel>
        </div>
      </section>
      <Footer />
      <FooterSub />
    </div>
  );
};

export default DineOut;
